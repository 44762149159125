import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import Box from "@mui/material/Box";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";
import clsx from "clsx";
import weelTheme from "./components/material-ui/theme";
import storeConfig from "./store/configureStore";
import AppRouter from "./router";
import Navbar from "./components/navbar";
import { RootState } from "./store/types";
import AppMenuBar from "./components/appMenuBar";
import { ax } from "./services/api/api";
import LogoutInterceptor from "./services/api/logoutInterseptor";

import "./global.css"; // Import the global CSS file

const storeConf = storeConfig();

const useStyles = makeStyles({
  root: {},
  appBody: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    zIndex: 0,
    marginTop: "1.25rem",
    paddingBottom: "3rem",
  },
  drawerOpen: {
    marginLeft: "12.5rem",
    width: "calc(100% - 200px)",
  },
  drawerClosed: {
    width: "100%",
  },
});

function App() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(window.innerWidth > 910);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const account = useSelector((state: RootState) => state.user.account);
  const userAccess = useSelector((state: RootState) => state.user.access);

  const minWidth = 910;

  ax.interceptors.response.use(
    (response) => response,
    (err) => {
      if ([401, 403].includes(err.response.status)) {
        localStorage.removeItem("persist:root");
        window.localStorage.clear();
        window.location.href = "/login";
      }
      Promise.reject(err);
    }
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      if (width < minWidth) {
        setIsOpen(true);
      }
    });

    return () => window.removeEventListener("resize", () => {});
  });

  return (
    <Box className={classes.root}>
      {/* AppMenuBar is the header bar at the top of the page. */}
      {account.active ? (
        <AppMenuBar
          open={isOpen}
          drawerWidth={200} //hardcoding because it's not gonna change.
          toggleNav={() => setIsOpen(!isOpen)}
        />
      ) : null}
      <LogoutInterceptor />
      <Box className={classes.appBody}>
        {/* Navbar is the drawer at the left side of the page. */}
        {account.active
          ? userAccess?.length > 0 && (
              <Navbar
                showNavbar={isOpen}
                toggleNavbar={() => setIsOpen(!isOpen)}
              />
            )
          : null}
        <Box
          className={clsx({
            [classes.drawerOpen]: isOpen && account.active,
            [classes.drawerClosed]: !isOpen && account.active,
          })}
          style={{
            transition: "margin-left 0.5s ease-in-out, width 0.3s ease-in-out",
            maxHeight: "86vh",
            overflow: "auto",
          }}
        >
          <Box style={{ padding: "0 2.5rem" }}>
            <AppRouter />
          </Box>
        </Box>
      </Box>
      <Toaster
        position="bottom-center"
        gutter={8}
        toastOptions={{
          success: {
            icon: "✅",
            duration: 6000,
            style: {
              fontFamily: "'Nunito'",
            },
          },
          error: {
            icon: "🚫",
            duration: 6000,
            style: {
              fontFamily: "'Nunito'",
            },
          },
        }}
      />
    </Box>
  );
}

ReactDOM.render(
  <Provider store={storeConf.store}>
    <PersistGate loading={null} persistor={storeConf.peristor}>
      <Router>
        <ThemeProvider theme={weelTheme}>
          <App />
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.querySelector("#root")
);
