import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "./header";
import { HeaderCell } from "./types";
import { Orders } from "../../store/orders/types";
import { useStyles as styles } from "./styles";
import StatusChip from "../../components/statusChip";
import "./style.css";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./react_dates_overrides.css";
import { useAppSelector } from "../../hooks";
import TagChip from "../../components/tagChip";
type OrderTableProps = {
  orders: Orders;
  getMoreOrders: () => void;
};

const headCells: Array<HeaderCell> = [
  {
    id: "nameOfDelivery",
    leftAlign: true,
    disablePadding: false,
    label: "Name",
    sortable: false, // Not sortable
  },
  {
    id: "status",
    leftAlign: false,
    disablePadding: false,
    label: "Status",
    sortable: false, // Not sortable
  },
  {
    id: "customer",
    leftAlign: true,
    disablePadding: false,
    label: "Customer",
    sortable: false, // Not sortable
  },
  {
    id: "tags",
    leftAlign: false,
    disablePadding: false,
    label: "Tags",
    sortable: false, // Not sortable
  },
  {
    id: "amount",
    leftAlign: true,
    disablePadding: false,
    label: "Amount",
    sortable: false, // Sortable
  },

  {
    id: "delivery_date",
    leftAlign: true,
    disablePadding: false,
    label: "Delivery Date",
    sortable: true, // Sortable
  },
  {
    id: "arrival_time",
    leftAlign: true,
    disablePadding: false,
    label: "Arrival Time",
    sortable: true, // Sortable
  },
];

const sanitizeDate = (deliveryDate: string): string => {
  const date = moment(deliveryDate).format("MM/DD/YYYY");
  return date;
};

const OrderTable: React.FC<OrderTableProps> = (props) => {
  const classes = styles();
  const rowsPerPage: number = 10; // the number of rows per page in the order table
  const nav = useNavigate();
  const [orderBy, setOrderBy] = useState<string>("");
  const [page, setPage] = useState(0);
  let { orders } = props;
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [filteredOrders, setFilteredOrders] = useState(
    Object.entries(orders.ordersList)
  );
  const org = useAppSelector((state) => state.organization);

  const [filters, setFilters] = useState({
    name: "",
    customer: "",
    status: "",
    type: "",
    delivery_preference: "",
    date_created: "", // Start date for the date_created range
    delivery_date_start: "",
    delivery_date_end: "",
  });

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, Object.entries(orders.ordersList).length]);

  const handleFilterChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;

    if (name) {
      setFilters((prevFilters) => {
        const newFilters = {
          ...prevFilters,
          [name]: value,
        };
        applyFilters(); // Apply filters as the user selects a value
        return newFilters;
      });
    }
  };
  const deliveryPreferences = ["None", "Before", "Between", "After"]; // Replace with your actual delivery preferences

  const statuses = [
    "With Driver",
    "Ready",
    "Cancelled",
    "Declined",
    "Absent",
    "Enroute",
    "Delivered",
    "Scheduled",
  ];
  const applyFilters = () => {
    const filtered = Object.entries(orders.ordersList)?.filter(([_, order]) => {
      let isPickup = order.type === "pickup";
      let address = isPickup ? order.from_address : order.to_address;
      let unit = isPickup ? order.from_unit : order.to_unit;
      // Mapping for status values
      const statusMapping = {
        "With Driver": "with_driver",
        Ready: "ready",
        Cancelled: "cancelled",
        Declined: "customer_declined",
        Absent: "customer_absent",
        Enroute: "return",
        Delivered: "delivered",
      };

      return (
        (!filters.name || order.name.includes(filters.name)) &&
        (!filters.customer ||
          address.toLowerCase().includes(filters.customer.toLowerCase()) ||
          unit.includes(filters.customer)) &&
        (!filters.status || order.status === statusMapping[filters.status]) &&
        (!filters.type ||
          order.type.toLowerCase() === filters.type.toLowerCase()) &&
        (!filters.delivery_date_start ||
          !filters.delivery_date_end ||
          (new Date(order.delivery_date).setHours(0, 0, 0, 0) >=
            new Date(filters.delivery_date_start).setHours(0, 0, 0, 0) &&
            new Date(order.delivery_date).setHours(0, 0, 0, 0) <=
              new Date(filters.delivery_date_end).setHours(0, 0, 0, 0)))
      );
    });

    setFilteredOrders(filtered);
  };

  const resetFilters = () => {
    setFilters({
      name: "",
      customer: "",
      status: "",
      type: "",
      delivery_preference: "",
      date_created: "", // Start date for the date_created range
      delivery_date_start: "", // End date for the date_created range
      delivery_date_end: "",
    });
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      Object.keys(orders.ordersList).length - page * rowsPerPage
    );

  // Sort handler
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
    sortedOrders();
  };

  const sortedOrders = () =>
    filteredOrders.sort((a, b) => {
      const orderA = a[1];
      const orderB = b[1];

      let comparison = 0;

      if (orderBy === "date_created") {
        comparison =
          new Date(orderA.date_created).getTime() -
          new Date(orderB.date_created).getTime();
      } else if (orderBy === "delivery_date") {
        comparison =
          new Date(orderA.delivery_date).getTime() -
          new Date(orderB.delivery_date).getTime();
      }

      return orderDirection === "asc" ? comparison : -comparison;
    });

  const activeFiltersCount = Object.entries(filters).reduce(
    (count, [key, value]) => {
      if (
        (key === "delivery_date_start" || key === "delivery_date_end") &&
        filters.delivery_date_start &&
        filters.delivery_date_end
      ) {
        if (!count.delivery_date) {
          count.total += 1;
          count.delivery_date = true;
        }
        return count;
      }

      // Handle other filters
      if (value) {
        count.total += 1;
      }

      return count;
    },
    { total: 0, date_created: false, delivery_date: false }
  ).total;

  // Replace with your actual status options

  const filterFields = [
    { name: "name", label: "Name", type: "text" },
    { name: "customer", label: "Unit-Address", type: "text" },
    { name: "status", label: "Status", type: "dropdown", options: statuses },

    {
      name: "delivery_preference",
      label: "Delivery Preference",
      type: "dropdown",
      options: deliveryPreferences,
    },

    { name: "date_created", label: "Created At", type: "date" },
    { name: "delivery_date", label: "Delivery At", type: "date" },
  ];
  return (
    <Paper className={classes.paper}>
      <Box p={2}>
        <Box display="flex" flexWrap="wrap" alignItems={"flex-start"} style={{ height:"31px" }}>
          {filterFields.map((field) => {
            if (field.type === "dropdown") {
              if (field.name !== "delivery_preference") {
                return (
                  <FormControl
                    key={field.name}
                    variant="outlined"
                    size="small"
                    style={{
                      minWidth: 180,
                      width: "auto",
                      marginBottom: 20,
                      marginRight: 30,
                    }}
                  >
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                      name={field.name}
                      value={filters[field.name] || ""}
                      onChange={handleFilterChange}
                      label={field.label}
                    >
                      {field?.options?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }
            } else if (field.type === "date") {
            } else {
              return (
                <TextField
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  value={filters[field.name] || ""}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  style={{
                    minWidth: 150,
                    width: 150,
                    marginBottom: 30,
                    marginRight: 30,
                  }}
                />
              );
            }
          })}

          <Button
            style={{ height: 35 }}
            variant="contained"
            color="primary"
            onClick={resetFilters}
          >
            Reset Filters {activeFiltersCount > 0 && `(${activeFiltersCount})`}
          </Button>
        </Box>
      </Box>

      <TableContainer className="table-delivery">
        <Table className={classes.table} size="small" aria-label="orders table">
          <TableHeader
            headerCells={headCells}
            handleRequestSort={handleRequestSort}
            orderDirection={orderDirection}
            orderBy={orderBy}
          />
          <TableBody>
            {filteredOrders.map((value, idx) => {
              let orderId = value[0];
              let order = value[1];
              let isPickup = order.type === "pickup";
              let address = isPickup ? order.from_address : order.to_address;
              let unit = isPickup ? order.from_unit : order.to_unit;

              return (
                <TableRow
                  className={classes.row}
                  tabIndex={-1}
                  key={`enhanced-table-checkbox-${idx}`}
                  onClick={() => {
                    nav(`/deliveries/${orderId}`, {
                      state: { order: order, orderId: orderId },
                    });
                  }}
                  style={{
                    backgroundColor: idx % 2 === 0 ? "#F6F8FCCC" : "#FFFFFF", // Alternate colors
                  }}
                >
                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">{order.name}</Typography>
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    <StatusChip status={order.status} />
                  </TableCell>
                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">{order.customer}</Typography>
                    <Typography variant="body1">{order.address}</Typography>
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    {org?.org_tags
                      ?.filter((tag) => order?.tags?.includes(tag.id))
                      ?.map((tag, idx) => (
                        <TagChip tag={tag} />
                      ))}
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="center"
                    padding="normal"
                  >
                    <Typography variant="body1">
                      ${order.amount?.toFixed(2)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    <Typography variant="body1">
                      {sanitizeDate(order.delivery_date)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={classes.cells}
                    align="left"
                    padding="normal"
                  >
                    {order.status === "routed" ? (
                      <Typography variant="body1">
                        {order?.arrival_time
                          ? sanitizeDate(order?.arrival_time)
                          : ""}
                      </Typography>
                    ) : (
                      <StatusChip status={"TBD"} />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && Object.entries(orders.ordersList).length > 0 && (
              <TableRow
                style={{ height: 38 * emptyRows, borderBottom: "none" }}
              >
                <TableCell colSpan={8} style={{ borderBottom: "none" }} />
              </TableRow>
            )}
            {Object.entries(orders.ordersList).length === 0 && (
              <TableRow style={{ height: 375, borderBottom: "none" }}>
                <TableCell colSpan={8} style={{ borderBottom: "none" }}>
                  <Typography
                    className={classes.emptyRowsTitle}
                    variant="h6"
                    align="center"
                    component="div"
                  >
                    No Deliveries Scheduled
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OrderTable;
