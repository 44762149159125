import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../store/types";
import MutualRoute from "./components/mutualRoute";
import ProtectedRoute from "./components/protectedRoute";
import AuthorizedRoute from "./components/authorizedRoute";
import ErrorRoute from "./components/errorRoute";
import OrdersContainer from "../orders";
import OrdersCreate from "../ordersCreate";
import LoginForm from "../auth";
import Settings from "../settings";
import AdminContainer from "../admin";
import OrderDetails from "../orderDetails";
import PatientDetails from "../patientDetails";
import PatientsContainer from "../patients";
import Pharmacy from "../pharmacy";
import { WEEL_ADMIN, COURIER_ADMIN, ORG_ADMIN } from "../services/roles";
import CreatePatient from "../patientCreate";
import CreatePharmacy from "../pharmacyCreate";
import Pharmacies from "../pharmacies";
import Drivers from "../drivers";
import CreateDriver from "../driversCreate";
import PharmacyDetails from "../pharmacyDetails";
import DriversDetails from "../driversDetails";
import SwitchMenu from "../switchMenu";
import LogDetails from "../logDetails";
import Logs from "../logs";
import BulkContainer from "../bulkActions";
import BulkActionCreate from "../bulkLocationsCreate";
import ServiceAgreement from "../serviceAgreement";
import TrakingMapContainer from "../orderDetails/components/trackDriver";
import ActiveRoute from "../driversDetails/activeRoute";
import Profile from "../profile";

const AppRouter: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.account);
  const org = useSelector((state: RootState) => state.organization);
  const authdPath = user.user_role === WEEL_ADMIN ? "/admin" : "/deliveries";

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <MutualRoute
              authenticated={user.active}
              authenticatedPath={authdPath}
              unauthenticatedPath="/login"
            />
          }
        />
        <Route
          path="/login"
          element={
            user.active ? (
              <MutualRoute
                authenticated={user.active}
                authenticatedPath={authdPath}
                unauthenticatedPath="/login"
              />
            ) : (
              <LoginForm authenticated={user.active} />
            )
          }
        />
        <Route
          path="/deliveries/create"
          element={
            <ProtectedRoute authenticated={user.active}>
              <OrdersCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deliveries"
          element={
            <ProtectedRoute authenticated={user.active}>
              <OrdersContainer />
            </ProtectedRoute>
          }
        />

        <Route
          path="/pharmacies"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[COURIER_ADMIN, ORG_ADMIN, WEEL_ADMIN]}
              currentRole={user.user_role}
            >
              <Pharmacies />
            </AuthorizedRoute>
          }
        />
        <Route
          path="/drivers"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[COURIER_ADMIN, ORG_ADMIN, WEEL_ADMIN]}
              currentRole={user.user_role}
            >
              <Drivers />
            </AuthorizedRoute>
          }
        />
        <Route
          path="/pharmacy"
          element={
            <ProtectedRoute authenticated={user.active}>
              <Pharmacy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deliveries/:deliveryId"
          element={
            <ProtectedRoute authenticated={user.active}>
              <OrderDetails />
            </ProtectedRoute>
          }
        />


        <Route
          path="/admin"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[WEEL_ADMIN]}
              currentRole={user.user_role}
            >
              <AdminContainer />
            </AuthorizedRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute authenticated={user.active}>
              <PatientsContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/:patientId"
          element={
            <ProtectedRoute authenticated={user.active}>
              <PatientDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/create"
          element={
            <ProtectedRoute authenticated={user.active}>
              <CreatePatient />
            </ProtectedRoute>
          }
        />

        <Route
          path="/pharmacies/create"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[COURIER_ADMIN, WEEL_ADMIN]}
              currentRole={user.user_role}
            >
              <CreatePharmacy />
            </AuthorizedRoute>
          }
        />
        <Route
          path="/pharmacy/:pharmacyId"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[COURIER_ADMIN, WEEL_ADMIN]}
              currentRole={user.user_role}
            >
              <PharmacyDetails />
            </AuthorizedRoute>
          }
        />
        <Route
          path="/drivers/create"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[COURIER_ADMIN, WEEL_ADMIN]}
              currentRole={user.user_role}
            >
              <CreateDriver />
            </AuthorizedRoute>
          }
        />
        <Route
          path="/drivers/:driverId"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[COURIER_ADMIN, WEEL_ADMIN]}
              currentRole={user.user_role}
            >
              <DriversDetails />
            </AuthorizedRoute>
          }
        />

        <Route
          path="/drivers/:driverId/activeroute"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[COURIER_ADMIN, WEEL_ADMIN]}
              currentRole={user.user_role}
            >
              <ActiveRoute />
            </AuthorizedRoute>
          }
        />
        <Route
          path="/drivers/:driverId/activeroute/mapview"
          element={
            <ProtectedRoute authenticated={user.active}>
              <TrakingMapContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacies/switch"
          element={
            <AuthorizedRoute
              authenticated={user.active}
              allowedRoles={[COURIER_ADMIN, ORG_ADMIN]}
              currentRole={user.user_role}
            >
              <SwitchMenu org={org} user={user} />
            </AuthorizedRoute>
          }
        />
        <Route
          path="/manage"
          element={
            <ProtectedRoute authenticated={user.active}>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute authenticated={user.active}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/logs"
          element={
            <ProtectedRoute authenticated={user.active}>
              <Logs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/logs/:logName"
          element={
            <ProtectedRoute authenticated={user.active}>
              <LogDetails />
            </ProtectedRoute>
          }
        />
        <Route path="/error" element={<ErrorRoute />} />
        <Route
          path="/bulk-actions"
          element={
            <ProtectedRoute authenticated={user.active}>
              <BulkContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bulk-actions/create"
          element={
            <ProtectedRoute authenticated={user.active}>
              <BulkActionCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy/service-agreement/:id"
          element={
            <ProtectedRoute authenticated={user.active}>
              <ServiceAgreement />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default AppRouter;
