//libraries
import {
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Typography,
  MenuItem,
  Divider,
  Chip,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

// components
import StatCard from "../statCard";
import MenuListComposition from "../menuPopupState";

//constants
import useStyles from "./styles";
import {
  fetchDriverPharmacies,
  fetchDriverStats,
  fetchDriversDetailsById,
  getActiveRoute,
  pharamcyAssignmentApi,
  searchPharmacyApi,
  unAssignmentApi,
} from "../../services/api/requests";
import { formatCanadianPhoneNumber } from "../../services/helper";

type CreateNewDriverProps = {
  orgId: string;
};

const pharmaciesMock = {
  pharmacies: {
    1: {
      name: "14th & Markham Shoppers",
      address: "50 Baylawn driver, Markham ON, Canada L3S 2R8",
      admin: "Admin",
      contact: "Contact",
      created: "Today",
    },
    2: {
      name: "Greg’s Shoppers Drugmart",
      address: "50 Baylawn driver, Markham ON, Canada L3S 2R8",
      admin: "Admin",
      contact: "Contact",
      created: "Today",
    },
  },
};

type AdminMetrics = {
  totalDeliveries: number;
  // deliveryDuration: number;
};

interface DriverInfo {
  org_id: number;
  org_name: string;
}

const ChangeDriverInfo: React.FC<CreateNewDriverProps> = (props) => {
  const nav = useNavigate();
  const [activeRouteId, setActiveRouteId] = useState(null);
  const [pharmacies, setPharmacies] = useState([]);
  const [newPharmacyId, setNewPharmacyId] = useState("");
  const [completedDeliveries, setCompletedDeliveries] = useState<AdminMetrics>({
    totalDeliveries: 0,
  });
  const [isLoading, setIsloading] = useState(false);
  const params = useParams();

  const [driverInfo, setDriverInfo] = useState({
    email: null,
    phone_number: null,
    total_deliveries: null,
    avg_completion_time: null,
  });
  const [driversPharmacies, setDriversPharmacies] = useState([]);
  const [activeRoute, setActiveRoute] = useState([]);
  const baseStyle = {
    fontSize: "0.75rem",
    fontWeight: "700",
    width: "6rem",
    height: "1.8rem",
    borderRadius: 5,
    marginBottom: 15,
  };

  const handlePharmacySearch = async (value) => {
    try {
      setNewPharmacyId(value);
      if (value.trim() !== "") {
        const response = await searchPharmacyApi(
          props.orgId,
          params.driverId,
          value
        );
        setPharmacies(response.data.organizations);
      } else {
        setPharmacies([]);
      }
    } catch (error) {}
  };
  const handleСlick = async (id: number) => {
    try {
      const payload = { pharmacy_id: id };
      await pharamcyAssignmentApi(props.orgId, params.driverId, payload);
      setNewPharmacyId("");

      setPharmacies([]);
      toast.success(`Successfully added as a new Organization`);

      await getDriverInfo();
    } catch (error) {}
  };

  const classes = useStyles();

  const getDriverInfo = async () => {
    try {
      const response = await fetchDriversDetailsById(
        props.orgId,
        params.driverId
      );
      setIsloading(true);
      const statsresponse = await fetchDriverStats(
        props.orgId,
        params.driverId
      );

      setCompletedDeliveries({
        totalDeliveries: statsresponse?.data?.total_deliveries,
      });
      setIsloading(false);
      const pharmaciesAssigResponse = await fetchDriverPharmacies(
        props.orgId,
        params.driverId
      );

      setDriversPharmacies(pharmaciesAssigResponse.data.organizations);

      setDriverInfo({
        email: response.data.email,
        phone_number: response.data.phone_number,
        avg_completion_time: null,
        total_deliveries: null,
      });
    } catch (error) {}
  };

  const getDriverActiveRoute = async () => {
    const activeRouteresponse = await getActiveRoute(
      props.orgId,
      params.driverId
    );

    setActiveRoute(activeRouteresponse?.data?.active_route);
    setActiveRouteId(activeRouteresponse?.data?.id);
  };

  useEffect(() => {
    getDriverInfo();
    getDriverActiveRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unAssignPharmacy = async (phamracyId) => {
    try {
      const payload = { pharmacy_id: phamracyId };
      await unAssignmentApi(props.orgId, params.driverId, payload);
      // setDriversPharmacies()
      setDriversPharmacies((prevPharmacies) =>
        prevPharmacies.filter((pharmacy: any) => pharmacy.org_id !== phamracyId)
      );

      toast.success(`Successfully removed Organization`);
    } catch (error) {}
  };

  return (
    <>
      {activeRoute.length > 0 && (
        <Grid
          item
          xs={4}
          md={3}
          container
          style={{ paddingTop: "0", paddingBottom: "0" }}
        >
          <Chip
            style={{
              backgroundColor: "#D0E7B7",
              color: "#47824A",
              ...baseStyle,
            }}
            size="small"
            label="Active Route"
          />
        </Grid>
      )}
      <Grid
        className={classes.gridContainer}
        style={{ alignItems: "baseline" }}
        container
        spacing={2}
      >
        <Grid item xs={7} md={7}>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper} elevation={2}>
              <Box className={classes.header}>
                <Grid
                  className={classes.gridContainer}
                  container
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Box className={classes.flexClass}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        className={classes.title}
                      >
                        Driver Details
                      </Typography>
                      <MenuListComposition />
                    </Box>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.detailText}
                    >
                      {formatCanadianPhoneNumber(driverInfo.phone_number)}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.detailText}
                    >
                      {driverInfo.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.card}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="subtitle1"
                  className={classes.subtitleClass}
                >
                  Assigned Pharmacies
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.textStyle}>
                Below are the pharmacies the driver has been assigned to.
              </Typography>
              <Box style={{ marginTop: 20 }}>
                {driversPharmacies?.length > 0 &&
                  driversPharmacies.map((item: DriverInfo, idx) => (
                    <Box
                      key={`assign-pharmacy-${idx}`}
                      className={classes.flexClass}
                    >
                      <Typography variant="body1" className={classes.textStyle}>
                        {item?.org_name}
                      </Typography>
                      <Button
                        className={classes.btnStyle}
                        onClick={() => unAssignPharmacy(item?.org_id)}
                      >
                        <Typography
                          variant="body1"
                          className={classes.textStyle}
                        >
                          Remove
                        </Typography>
                      </Button>
                    </Box>
                  ))}
              </Box>
              <Typography
                variant="body1"
                className={classes.textStyle}
                style={{ marginTop: 75 }}
              >
                Enter the pharmacy name below to assign the driver to the <br />{" "}
                pharmacy.
              </Typography>

              <Grid
                className={classes.gridContainer}
                container
                spacing={2}
                alignItems="center"
                style={{ marginTop: 12 }}
              >
                <Grid item xs={12} md={7} style={{ paddingBottom: 0 }}>
                  <TextField
                    className={classes.textField}
                    style={{ width: "100%" }}
                    value={newPharmacyId}
                    variant="outlined"
                    onChange={(e) => handlePharmacySearch(e.target.value)}
                    label="Pharmacy name"
                    placeholder="Search pharmacy name here..."
                    margin="normal"
                    size="small"
                  />
                </Grid>
              </Grid>

              <Stack display="flex" flexDirection="column">
                {pharmacies.map((pharmacy: any, index) => {
                  return (
                    <>
                      <MenuItem
                        key={index + 1}
                        style={{
                          justifyContent: "space-between",
                          fontSize: 16,
                          lineHeight: "22px !important",
                        }}
                        onClick={() => handleСlick(Number(pharmacy.org_id))}
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.subtitleClass}
                        >
                          {pharmacy?.org_name}
                        </Typography>
                      </MenuItem>
                      <Divider />
                    </>
                  );
                })}
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.card}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="subtitle1"
                  className={classes.subtitleClass}
                >
                  Routing
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.textStyle}>
                To view the current route that this driver is on click the
                button below.
              </Typography>

              <Grid
                className={classes.gridContainer}
                container
                spacing={2}
                alignItems="center"
                style={{ marginTop: 12 }}
              >
                <Grid
                  item
                  xs={4}
                  md={3}
                  container
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      nav(`/drivers/${params.driverId}/activeroute`, {
                        state: {
                          activeRoute: activeRoute,
                          orgId: props.orgId,
                          driverId: params.driverId,
                          activeRouteId: activeRouteId,
                        },
                      });
                    }}
                    className={classes.addPharmacy}
                  >
                    Active Route
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          className={classes.gridTags}
          container
          spacing={2}
          alignItems="center"
          xs={5}
          item
          md={5}
        >
          <StatCard
            label="Total Deliveries <br/> Completed"
            stat={completedDeliveries?.totalDeliveries}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChangeDriverInfo;
