import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { RootState } from "../store/types";
import PasswordResetForm from "./components/passwordResetForm";
import useStyles from "./styles";

const Profile: React.FC<{}> = () => {
  const classes = useStyles();
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <PasswordResetForm user_id={Number(user.account.user_id)}/>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
