import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-hot-toast";
import { updateUserPassword } from "../../../services/api/requests";

interface PasswordResetFormProps {
  user_id: number
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    padding: theme.spacing(3),
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
}));

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({ user_id }) => {
  const classes = useStyles();
  
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  
  const handleSubmit = async () => {
    if (newPassword === "") {
      setError("You need to provide a new password!")
      return 
    } 
    
    if (newPassword !== confirmPassword) {
      setError("New Password and Confirm Password do not match!");
      return;
    }

    if (currentPassword === "") {
      setError("You need to enter the current password!")
      return
    }

    const password_obj = { "current_password": currentPassword, "new_password": newPassword, "confirm_new_password": confirmPassword };

    let resp = await updateUserPassword(user_id, password_obj);

    if (resp.status ===  401) {
      setError("Failed to validate the current password!");
    }

    if (resp.status === 500) {
      setError("Failed to update the password!");
    }

    if (resp.status === 200) {
      setError("");
      toast.success("Password updated!");
    }

  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Password Reset
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Current Password"
            type="password"
            fullWidth
            variant="outlined"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography className={classes.error}>{error}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Save Password
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PasswordResetForm;
